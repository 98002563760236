.svg {
  display: inline-block;
  vertical-align: -0.15em;

  &.svgColor > path {
    fill: currentColor;
  }

  &.strokeWidth {
    stroke: currentColor;
  }
}

.img{
  display: inline-block;
  vertical-align: -0.15em;
}