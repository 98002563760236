
.listingTableContainer{
  overflow-x: hidden;
  border-radius: 0% !important;
}
.listTable{
  tr{
    td{
      border : none;
    }
  }
  .tableRowEffect{
    &:hover{
      box-shadow: 4px 4px 40px 2px rgba(0,0,0,.16);
    }
    &:nth-child(odd) {
      background-color: #f5f5f5;
      &:hover{
        background-color: #fff;
      }
    }
    &:nth-child(even) {
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
    }
  }
}
.onRowClick {
  cursor: pointer;
}