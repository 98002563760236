@import '../../../main';

$darkBlack: #303030;

.spectroReadings {
  @include gridFlow;
  place-items: center;

  & > div {
    border: 1px solid #d1d1d1;
    padding: 3px 4px;
    border-radius: $borderRadius;
    min-width: 50px;
    max-width: 56px;

    &.dataNotInRange {
      border-color: #ff3d60;
      background-color: #fff2f2;
    }
  }
}

.spectroEafContainer {
  .spectroListingViewContainer {
    grid-template-rows: 90px 1fr;
  }
}

.spectroListingViewContainer {
  display: grid;
  grid-template-rows: 115px 1fr;
}

.selectWrapper {
  position: relative;
  .arrow {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.elementBox {
  display: flex;
  height: 2.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: #606060;
  border-radius: 0.25rem;
  background-color: #6060601a;
}

.elementBoxesContainer {
  display: flex;
  gap: 0.25rem;
  min-height: 40px;
}

.mobTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  padding-bottom: 8px;
  line-height: 12px;
  text-transform: uppercase;
  color: #909090;
}
.mobTitle:nth-child(even) {
  padding: 10px;
}
.mobInnerSection:nth-child(odd) {
  div {
    border-right: 1px solid #eeeeee;
  }
}
.mobInnerSection {
  border-top: 1px solid #eeeeee;
  padding: 10px 5px;
}
.mobInnerSection:nth-child(1) {
  border-top: none;
}
.mobInnerSection:nth-child(2) {
  border-top: none;
}
.innerText {
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  color: #303030;
}

.spectroReadingGrid2 {
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem 0 2rem;

  button {
    padding: 0px 0.625rem;
  }

  .btnContainer {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    color: #1579be;
    font-weight: 700;
    font-size: 0.875rem;
    i {
      font-weight: 400;
      font-size: 1.5rem;
    }
  }
}
.spectroReadingGrid {
  padding: 0 1rem 0 2rem;

  .spectroReadingInner {
    padding: 0.75rem 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
}

.spectroReadingHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.25rem;

  .spectroReadingTag {
    font-size: 0.625rem;
    font-weight: 700;
    padding: 0.25rem 1rem;
    border-radius: 0.25rem;
    height: 2.8rem;
  }

  .spectroReadingDate {
    font-size: 0.75rem;
    font-weight: 400;
    color: #303030;
  }
}

.cmListGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1.5rem 1rem 0 2rem;
  // margin: 0 10px;
  border-top: 1px solid #eee;
  // gap: 10px;
}

.tableContainer {
  @include boxStyles($isPadding: false, $isMargin: false);
}

.tableHead {
  background-color: $headerColor;

  th {
    @include headerStyle(0.85rem);
    color: rgba(0, 0, 0, 0.87);
    padding: 0.65rem 0.85rem;
    white-space: nowrap;
    border: none;
  }
}

.noDataRow {
  height: 150px !important;
}

.tcSave {
  border: none;
  background-color: $quinaryColor;
  color: #fff;
  border-radius: 5px;
  padding: 6px 20px;
  min-width: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
}

.tableBody {
  tr {
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      cursor: pointer;
    }
  }

  td {
    font-size: 0.85rem;
    padding: 0rem 0.85rem;
    color: #333333;
    border-bottom: 1px solid #eeeeee;
  }
}

.elementBox {
  @include flexCenter;
  flex-direction: column;
  color: #3d3d3d;
  font-size: 0.75rem;
}

@include mediaScreen($mediumScreenBreakpoint) {
  .spectroReadings {
    // @include gridTemplateColumns(repeat(auto-fit, minmax(10px, 70px)));
    place-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    margin: 10px 0px;
  }
  .noReadingsFound {
    @include flexCenter;
    height: 150px;
    text-transform: uppercase;
  }

  .spaceHifen {
    padding: 0 8px;
  }

  .searchTitle {
    margin: 10px 0px 15px 0px;
    font-weight: bold;
  }
  .spectroCard {
    @include boxStyles($isPadding: false, $isMargin: false);
    border: none;
    margin: 10px 0px;
    min-height: 150px;
    display: block;
    position: relative;
    border-left: 5px solid $quaternaryColor !important;

    .spectroCardBody {
      padding: 10px;

      .spectroCardHeader {
        @include flexBetween;
      }

      .viewDetails {
        @include flexCenter;
        font-size: 13px;
        white-space: nowrap;
        color: $lightTitleColor;
        margin-left: 20px;
      }

      .customerName {
        color: $senaryColor;
        font-weight: bold;
        font-size: 14px;
      }

      .spectroDetailsContainer {
        @include gridTemplateColumns(1fr);
        margin-top: 10px;

        .gradeCapsule {
          padding: 4px 12px;
          background-color: $headerColor;
          border-radius: 20px;
          font-weight: normal !important;
          text-transform: uppercase;
        }

        .spectroReadingTitle {
          margin-top: 10px;
          font-weight: bold;
        }

        .spectroDetail {
          @include flexBetween;
          padding: 7px 0px;
          border-bottom: $border;
        }

        .spectroDetailTitle {
          color: $lightTitleColor;
          font-size: 13px;
        }
        .spectroDetailValue {
          font-weight: bold;
          font-size: 13px;
          margin-left: 30px;
        }

        .spectroStatusView {
          padding: 2px 8px;
          font-weight: normal;
        }
      }
    }
  }

  .spectroCard:first-of-type {
    margin-top: 20px;
  }
}

.spectro__listing__btn {
  @include flexCenter;
  color: #1579be;
  background-color: #e8f2f8;
  border: 1px solid #1579be;
  gap: 5px;
  font-size: 12px;
  transition: 300ms ease;
  padding: 8px 20px;
  border-radius: 4px;
}

.spectro__listing__btnContainer {
  @include flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.filterHeader {
  padding: 20px 16px;
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 8px;
}
.horizontalLine {
  height: 1px;
  background-color: #eeeeee;
  width: 100%;
  margin: 15px 0;
}
.filterBody {
  padding: 0px 16px;
}
.chipContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  max-width: 360px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.filterSubHeader {
  font-size: 12px;
  font-weight: 700;
  padding-bottom: 8px;
}
.inputbox_date {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgray;
  width: 100%;
  font-size: 14px;
}
.dateRangeInput {
  display: flex;
  align-items: center;
  gap: 10px;
}
.filterBtnContainer {
  position: absolute;
  width: -webkit-fill-available;
  padding: 1rem 1.25rem;
  bottom: 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  background-color: $secondaryColor;
  button {
    border-radius: 0.5rem;
  }
}
.footerRmContainer {
  position: absolute;
  width: 100%;
  bottom: 20px;
  @include flex;
  padding: 0 16px;
  gap: 15px;
  button {
    padding: 0;
  }
}
.iconGap {
  padding-right: 8px;
}
.selectBox {
  width: 100%;
  height: 40px;
  padding: 8px;
  border-color: #909090;
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 14px;
  // width: $inputFieldLength;
  background-color: white;
  fieldset {
    display: none;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  :focus {
    outline: none;
  }
  :active {
    outline: none;
  }
}
.optionSelect {
  width: 100%;
  height: 40px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #909090;
  :focus {
    outline: none;
  }
  :active {
    outline: none;
  }
  fieldset {
    display: none;
  }
}
.filterButton {
  all: unset;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 4px;
  cursor: pointer;
  transition: 300ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  i {
    font-size: 1.3rem;
    line-height: unset;
  }
  span {
    font-size: 0.8rem;
    line-height: 1rem;
    color: #909090;
    font-weight: 600;
  }
}
.filterButton:hover {
  box-shadow: 2px 2px 8px #909090a4;
  background-color: #157abe27;
  border: 1px solid #1579be;
  i {
    color: #1579be;
  }
  span {
    color: #1579be;
  }
}

.muiSelect {
  fieldset {
    top: -1px !important;
  }
  legend {
    display: none;
  }
}

.rmFilterHeader {
  @include flexBetween;
  font-size: 18px;
  font-weight: 600;
  color: $darkBlack;
  padding-inline: 16px;
  padding-top: 16px;
  button {
    padding: 0;
  }
}

.closeBtnAnimate {
  transition: all 0.3s ease-in-out;
  font-size: 1.5rem;
  color: $breadcrumbColor;

  &:hover {
    transform: rotate(90deg);
    color: $lightBlue;
  }
}

.iconBtn {
  padding-right: 8px;
  font-size: 24px;
}
.tippyContainer {
  font-size: 0.75rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: center;
  align-items: flex-start;
  padding: 0.4rem;

  .tippyContainerRow {
    gap: 0.2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .arrow_aling {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .tippy-tooltip.tomato-theme .tippy-svg-arrow {
    fill: rgba(48, 48, 48, 0.8);
  }
}

.closeButton {
  cursor: pointer;
  position: absolute;
  right: 0.25rem;
  top: 0.75rem;
}

.filterDrawerContainer {
  display: flex;
  flex-direction: column;
  height: 100%; // Ensure the container takes up the full height of the drawer
}

.filterHeaderContainer {
  flex: 0 0 auto; // Height based on its content
}

.filterBody {
  flex: 1 1 auto; // This will grow and take up available space
  overflow-y: auto; // Enables vertical scrolling when needed
  padding-bottom: 6rem;
}
