.overrideLayoutOpen {
  background-color: #fff;
  padding: 20px 30px;
  display: flex;
  gap: 17px;
  justify-content: space-between;
  align-items: center;
}

@media(max-width : 768px){
  .overrideLayoutOpen {
    padding: 10px 1rem;
  }
}