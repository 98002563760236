.autocomplete {
  position: relative;
}

.autocomplete input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgray;
  width: 100%;
  font-size: 14px;
}

.suggestionsOverlay {
  position: absolute;
  background-color: white;
  border: 1px solid lightgray;
  border-top: none;
  width: 100%;
  z-index: 12;
  max-height: 300px !important;
  min-height: 100px;
  overflow-y: auto;
}

.suggestionsOverlay div {
  padding: 8px;
  cursor: pointer;
}

.suggestionsOverlay div:hover {
  background-color: black;
  color: white;
}

.suggestion {
  cursor: pointer;
}

.suggestionActive {
  background-color: black;
  color: white;
}

.loading,
.noData {
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.suggestionsOverlay .noData {
  padding: 10px;
  text-align: center;
}
