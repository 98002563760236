.filterBoxes {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 0.625rem 1.92rem;
  div {
    background-color: #e7e7e7;
    border-radius: 0.25rem;
  }
}
