$red: rgba(228, 62, 43, 0.12);
$green: rgba(43, 162, 76, 0.12);
$dark-gray: #303030;
$font-weight: 500;
$font-size: 12px;
$font-weight-bold: 400;
$boxWidth: 100px;

.tc_element_box {
  border-radius: 4px;
  height: 40px;
  width: $boxWidth;
  padding-left: 8px;
  text-align: left;
  color: $dark-gray;

  .ele_symbol_style {
    font-weight: 500;
    font-size: $font-size;
    padding: 4 0 4 0;
  }

  .ele_range_style {
    font-weight: $font-weight-bold;
    font-size: $font-size;
    font-weight: 700;
    white-space: nowrap;
  }
}

.tc_element_box_red {
  @extend .tc_element_box;
  background: $red;

  .ele_symbol_style {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;
  }
}

.tc_element_box_green {
  @extend .tc_element_box;
  background: $green;

  .ele_symbol_style {
    font-style: normal;
  }
}

.tc_element_box_grey {
  @extend .tc_element_box;
  background: #6060601a;
}
.tc_element_box_warn {
  @extend .tc_element_box;
  background: rgba(227, 151, 0, 0.16);
}
