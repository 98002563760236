@import '../../../main';

$green: rgba(43, 162, 76, 1);
$fadedGreen: rgba(43, 162, 76, 0.16);
$red: rgba(228, 62, 43, 1);
$fadedRed: rgba(228, 62, 43, 0.1);

$orange: rgba(227, 150, 0, 1);
$fadedOrange: rgba(227, 150, 0, 0.1);

$purple: rgba(150, 54, 225, 1);
$fadedPurple: rgba(150, 54, 225, 0.1);

$border: 1.5px solid #ffffff;

.header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  height: $headerZIndex;
  padding: 15px;
  width: -webkit-fill-available;
  border-bottom: 1.5px solid #eee;
  transition: $sidebarTransition;
  z-index: 999;
  height: $headerHeight;
  background: #ffffff;
}

.heatDetailsHeaderText {
  color: $secondaryTextColor;
  font-size: 1rem;
  font-weight: 500;
}

.spectroDetailText {
  color: $secondaryTextColor;
  font-size: 0.8125rem;
  font-weight: 700;
  padding-left: 0.25rem;
}

.heatIdText {
  color: #606060;
  font-size: 0.8125rem;
  font-weight: 500;
  padding-left: 0.25rem;
}

.backActionIcon {
  cursor: pointer;
  font-size: 24;
  color: $breadcrumbColor;
}

.nameTagContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 10px;
  padding: 0 8px;
  height: 20px;
}

.backActionIcon:hover {
  color: #007fe8;
}

.breadcrumbStyle {
  font-weight: 500;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: $breadcrumbColor;
  padding-left: 12px;
  cursor: pointer;
}
.breadcrumbStyle:hover {
  color: #007fe8;
}

.smallText {
  font-size: 16px;
  font-weight: 500;
  color: #303030;
  margin-right: 4px;
}

.othersTag {
  color: $green;
  background: $fadedGreen;
}
.diTag {
  color: $red;
  background: $fadedRed;
}
.gciTag {
  color: $purple;
  background: $fadedPurple;
}
.ssTag {
  color: $orange;
  background: $fadedOrange;
}

.draftTag {
  color: $orange;
  background: $fadedOrange;
  padding: 0.25rem 0.5rem;
  margin-left: 0.5rem;
  border-radius: 0.25rem;
}

.tagBox {
  font-size: 12px;
  font-weight: 700;
  color: #9636e1;
  background-color: #9636e110;
  height: 20px;
  padding: 0 18px;
  border-radius: 4px;
  margin-right: 4px;
  @include flexCenter;
}
.breadCrumbContainer {
  font-size: 12px;
  align-items: center;
  color: $successColor;
  display: flex;
  i {
    font-size: 1rem;
    padding: 10px 0;
  }
}
.controlPlanLabel {
  color: $secondaryTextColor;
  font-size: 1rem;
  font-weight: 500;
}
.content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.titleContainer {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #303030;
  @include flexCenter;
}

.title {
  margin-top: 4px;
  padding-left: 15px;
}

.notTitle {
  margin-left: 8px;
  font-size: 18px !important;
  color: #303030;
  font-weight: 600;
  display: flex;
  justify-content: center;
  gap: 10px;
  height: inherit;
  align-items: center;
}

.actionContainer {
  @include grid;
  grid-auto-flow: column;
  gap: 10px;
}

.trialBox {
  color: #e39600;
  background-color: rgba(227, 150, 0, 0.16);
  padding: 2px 5px;
  font-weight: 600;
  font-size: 14px;

  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 2px;
  border-radius: 4px;
}
.userProfileBox {
  display: flex;
  place-items: center;
  cursor: pointer;
}
.userProfile {
  display: grid;
  place-items: center;
  grid-auto-flow: column;
  gap: 5px;
  padding-right: 15px;

  .profile {
    padding-left: 5px;
    display: flex;
    flex-direction: column;

    & > span:first-child {
      font-size: 14px;
      color: #000;
      font-weight: 700;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 130px;
      overflow: hidden;
    }

    & > span:last-child {
      font-size: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 130px;
      overflow: hidden;
    }
  }
}

@include mediaScreen($largeScreenBreakpoint) {
  .header {
    padding: 0.5rem;

    .content {
      border-left: unset;
      border-right: unset;
      margin: unset;
      .titleContainer {
        font-size: 16px;
        padding-left: 15px;
        .title {
          padding-left: 0;
        }
      }
    }
  }
}

.userNameLogout {
  font-weight: 700;
  font-size: 16px;
  padding-top: 8px;

  text-align: center;
  color: #e8f2f8;
}
.companyNameLogout {
  padding-top: 4px;
  font-weight: 400;
  text-align: center;
  color: #e8f2f8;
  padding-bottom: 15px;
  border-bottom: 1px solid $breadcrumbColor;
}

.logoutStyle {
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: flex-end;
  height: 32px;
  color: #e8f2f8;
  gap: 8px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin: 0.5rem 0;
  transition: 0.2s background ease-in-out;
  &:hover {
    background-color: #1579be;
  }
  .btnLogoutText {
    line-height: 14px;
  }
  i {
    font-size: 1rem;
  }
}

.AvatarInitials {
  padding-top: 2%;
  height: 60;
  width: 60;
  font-size: 24;
}
.avtarAlingment {
  padding-top: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.mobLogoutStyle {
  position: absolute;
  margin-top: 3%;
  width: 223px;
  height: auto;
  right: 5%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  border-radius: 8px;
}

.rawMaterialHeader {
  font-size: 16px;
  font-weight: 500;
  color: rgba(48, 48, 48, 1);
}

.logoutConfirmation {
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  background: $lightBlue;

  .logoutText {
    font-weight: 700;
    color: #e8f2f8;
    font-size: 0.75rem;
    margin-bottom: 0.625rem;
    animation: slideIn 0.7s ease-out forwards;
  }
  .logoutBtnContainer {
    display: flex;
    gap: 0.5rem;
    animation: slideDown 0.7s ease-out forwards;

    button {
      all: unset;
      cursor: pointer;
      display: grid;
      grid-template-columns: auto auto;
      justify-content: center;
      align-items: flex-end;
      font-weight: 700;
      font-size: 0.75rem;
      padding: 0.5rem 0.75rem;
      gap: 0.25rem;
      flex: 1 0 0;
      border-radius: 0.25rem;
      backdrop-filter: blur(0.125rem);
      i {
        font-size: 1rem;
        grid-column: 1;
      }
      span {
        grid-column: 2;
        line-height: 14px;
      }
    }

    .logoutBtn {
      border: 1px solid #73afd8;
      background: $lightBlue;
      color: #e8f2f8;
    }
    .cancelLogout {
      background: #e8f2f8;
      color: $lightBlue;
    }
  }
}

.headerDiv {
  display: flex;
}

@include mediaScreen($mediumScreenBreakpoint) {
  .logoutStyle {
    margin: 8px 0px 8px 0px;
  }
  .mobLogoutOnclickout {
    margin-top: -10vh;
    position: absolute;
    height: 100vh;
    width: 100vw;
    left: 0;
  }
  .header {
    right: unset;
  }
  .titleContainer {
    margin-left: 45px;
  }
  .actionContainer {
    display: none !important;
  }
  .title {
    font-size: 1rem;
    margin-top: unset;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(30%);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
