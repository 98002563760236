@import './main';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  a {
    text-decoration: none;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

body {
  background-color: #f7f9fc !important;
  font-family: $fontFamily;
  letter-spacing: 0.3;
  line-height: unset;
}

.gridRowFullWidth {
  @include gridFullWidth;
}

.hideFromLargeScreenBreakpoint {
  @include mediaScreen($largeScreenBreakpoint) {
    display: none !important;
  }
}

.bookbtn {
  background-color: $quaternaryColor;
  padding: 6px 10px;
  color: $headerColor;
  border-radius: 6px;
  min-width: 180px;
  border: none;
}

.createCmBtn {
  background-color: #1cbb28;
  padding: 6px 10px;
  color: $headerColor;
  border-radius: 6px;
  min-width: 180px;
  border: none;
  margin-right: 10px;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
