@import '../../main';
.content {
  scroll-behavior: smooth;
  overflow-x: hidden;
  height: 400px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 4px;
  color: #606060;
}
::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(221, 221, 221, 1);
  border-radius: 30px;
}
::-webkit-scrollbar-thumb {
  background: #909090;
  border-radius: 10px;
}
.button {
  background: #217ec0;
  box-shadow: 12px 8px 24px rgba(33, 126, 192, 0.16);
  color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 9px 20px;
  gap: 10px;
  border: none;
}
.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
}
.modalDiv {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  min-height: 518px;
  min-width: 60%;
  @include boxShadow;
  border-radius: 16px;
}
.acceptance {
  a {
    color: #1579be;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

@include mediaScreen($mediumScreenBreakpoint) {
  .modalDiv {
    min-width: 90%;
  }
}
