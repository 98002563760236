@import '../../../main';

.align_add_edit_rawMaterial {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalDiv {
  box-shadow: 0px 0px 6px 3px rgb(0 0 0 / 8%);
  background-color: white;
  z-index: inherit;
  outline: none;
  cursor: default;
  min-width: 700px;
  width: calc(100% - 200px);
  position: absolute;
  right: 0;
  height: 100%;
  overflow-y: auto;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0px;

    position: sticky;
    top: 0;
    z-index: inherit;
    background-color: inherit;

    .headerTxt {
      font-size: 20px;
      font-weight: 600;
    }

    .icon {
      cursor: pointer;
      color: $quinaryColor;
      font-size: 28px;
    }
  }

  & > main {
    .form {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      gap: 20px;
      padding: 0 15px;
      margin-top: 20px;
    }

    .modalTableContainer {
      margin: 20px 0 0;
      border-radius: 0;
      max-height: 300px;

      &::-webkit-scrollbar-thumb {
        background: #49c2f0;
        border-radius: 10px;
      }

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      .modalTable {
        border-collapse: collapse;

        .modalTableHead {
          z-index: inherit;
          border: none;

          tr,
          th:not(:first-child) {
            padding: 10px;
          }

          th:first-child {
            padding: 10px;
          }
        }

        .modalTableBody {
          tr,
          td {
            padding: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100px;
          }

          th {
            padding: 10px;
          }
        }

        .modalTableFooter {
          tr,
          th,
          td {
            padding: 10px;
          }
        }
      }
    }
  }

  & > footer {
    &.modalAddFooter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
    }

    &.spectroSuggestionsContainer {
      padding: $paddingSmall;

      .spectroSuggestions {
        border: 1px solid #ff3d60;
        border-radius: 4px;
        padding: 8px $paddingSmall;

        .header {
          display: flex;
          align-items: center;

          & > span {
            @include headerStyle;
            margin-left: 10px;
          }
        }

        .suggestion {
          margin: 8px 0 15px 5px;
          font-size: 15px;
          margin-bottom: 5%;
        }

        .estimate {
          @extend .suggestion;
          margin: 10px 0 5px 5px;
        }
      }
    }
  }
}

.addDilSuggestion {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  padding-left: 35px;
}

.addDilSuggestionTitleContainer {
  row-gap: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.warningMsg {
  color: #d58c00;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: 700;
  i {
    font-size: 1.5rem;
    margin-right: 0.25rem;
    font-weight: normal;
  }
}

.headerTagStyle {
  margin-bottom: 0.25rem;
  padding: 0.2rem 0.3rem;
  background-color: #f5f5f5;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 700;
}

.addDilSuggestionTitle {
  display: flex;
  gap: 0.5rem;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #606060;
}

.addDilSuggestionFurnacesize {
  padding-top: 13;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #303030;
}

.suggestionHeader {
  align-items: center;
  display: flex;
  font-weight: 600;
  gap: 15;
  font-size: 1.1rem;
  padding: 1rem 20px;
}
.suggestionHeaderText {
  padding-left: 12px;
  font-weight: 700;
  font-size: 14px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.suggestionBox {
  background: rgba(158, 223, 148, 0.1);
  min-width: $boxWidth;
  height: 40px;
  background: #e8f2f8;
  border: 1px solid #1579be;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #303030;
  padding: 4 10;
  .qtyBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-weight: 700;
    .percentText {
      font-weight: 500;
    }
  }
}
.elementSuggestionBox {
  background: rgba(158, 223, 148, 0.1);
  border: 1px solid #9edf94;
  border-radius: 4px;
  padding: 2px 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #3d3d3d;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  width: $elementBoxWidth;
  grid-row-gap: 5px;
  padding: 8px;
  line-height: 18px;
}
.spectroSuggestions {
  border-top: none;
  border-radius: 4px;

  width: 100%;
}
.headerFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #606060;
  padding-bottom: 12px;
  white-space: nowrap;
}
.date_time_style {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.02em;
  color: #909090;
}
.elementSymbol {
  padding-top: 4;
  padding-left: 10;
  padding-bottom: 2;
}

.cmRedirectIcon {
  padding-left: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  color: #1579be;
}

.elementBox {
  width: 100px;
  height: 40px;
  border-radius: 4px;
  background: #f5f5f5;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #606060;
}

.mainClass {
  display: flex;
  // margin: -20px;
  flex-direction: column;
  background: white;
}
.tc_body {
  background: #f5f5f5;
  margin-bottom: 2px;
  padding: 0.75rem 0 1.25rem 1.8rem;
  position: sticky;
  top: 76px;
  z-index: 2;
}

.tc_body_fin {
  background: #f5f5f5;
  padding: 8 0 16 30;
  border-bottom: 1px solid #aaaaaa;
}
.elementText {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  padding-left: 10;
  color: #303030;
}
.elementBoxTc {
  width: 100px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #3d3d3d;
  padding: 4.2 0 0 9.8;
}
.sampleTypeFinalBox {
  display: flex;
  width: 50px;
  height: 20px;
  background: #f5ebfd;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #9636e1;
  align-items: center;
  justify-content: center;
}
.sampleTypeBathBox {
  display: flex;
  padding: 0 8px;
  height: 20px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #009797;
  background: rgba(0, 151, 151, 0.1);
  align-items: center;
  justify-content: center;
}
.reading_index_aling {
  display: flex;
  flex-direction: row;
  // padding-bottom: 8px;
  justify-content: space-between;
}
.aling_viewMore_reading {
  display: grid;
  grid-template-columns: 9fr 1fr;
}
.view_more_style {
  display: flex;
  align-items: flex-end;
}
.readingBox {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px 0px;
  flex-wrap: wrap;
}
.newBox {
  padding-bottom: 10px;
}
.header_detail_pos {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 1.88rem;
  gap: 1rem;
  background: #f5f5f5;
  z-index: 3;
  position: sticky;
  top: 0;
  .header_details_numbers {
    display: flex;
    gap: 1.5rem;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .valueData {
      max-width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .dataBox {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    color: #000;
    font-size: 0.75rem;
    .labelData {
      font-weight: 500;
    }
    .valueData {
      font-weight: 700;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }
}
.readingContainerBox {
  display: flex;
}
.specReadingsOneTwo {
  padding-left: 20px;
}
.iconBox {
  padding: 14px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  * {
    font-size: 16px;
    font-weight: 700;
  }
}
.specReadingsPadd {
  padding-left: 50px;
}
.viewReading {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #1579be;
  cursor: pointer;
}
.aling_view_reading {
  display: flex;
  align-items: flex-end;
}
.suggestions {
  margin-top: 10px;
  font-size: 15px;
  // margin: 8px 0 15px 5px;
  padding: 15px 15px 15px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.tablehead {
  background-color: $quinaryColor;
}

.tableheadcell {
  background-color: $quinaryColor;
  color: #fff;
}

.switchText {
  font-size: 12px;
  font-weight: bold;
  color: $secondaryTextColor;
}
.viewModeText {
  font-weight: normal;
  color: $breadcrumbColor;
}

.tcSave {
  border: none;
  background-color: $quinaryColor;
  color: #fff;
  border-radius: 5px;
  padding: 6px 20px;
  // text-transform: uppercase;
  min-width: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
}
.spectext {
  // padding-right: 800px;
  font-weight: 500;
  font-size: 20px;
  color: #909090;
  display: flex;
  gap: 5px;
  align-items: center;
}

.autocompleteChip {
  background-color: $quinaryColor;
  color: #fff;
}

.autocompleteChipIcon {
  color: #fff;
}

.TC_box {
  background: #f5f5f5;
  width: 100.5%;
  // height: 108px;
  padding-bottom: 20px;
  .TC_heading {
    padding: 20 0 12 40;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: #606060;
  }
  .elementBox_alingment {
    min-width: 100px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: #3d3d3d;
  }
  .element_name {
    padding: 5 0 2 10;
  }
  .element_range {
    padding-left: 10;
    padding-right: 5px;
  }
}
.tcalingbox {
  padding-left: 24px;
  display: grid;
  grid-template-columns: 1fr 135px;
}
.viewTolernce {
  color: #1579be;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  font-size: 0.8125rem;
  font-weight: 600;
  i {
    transition: 300ms ease-in-out;
  }
}
.tolerenceBox {
  padding: 0.2rem 0.6rem;
  font-size: 0.75rem;
  font-weight: 700;
  color: #909090;
  background-color: #fff;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  min-width: 100px;
}
.editButton {
  all: unset;
  width: 160;
  height: 40px;
  border: 1px solid #1579be;
  backdrop-filter: blur(2px);
  border-radius: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1579be;
  background-color: #fff;
  margin-right: 16px;
}
.spectroreading_box {
  padding: 0.75rem 0 1.25rem 1.8rem;
}
.predictedComp {
  font-weight: bold;
  color: rgba(96, 96, 96, 1);
}
.bathNoPadding {
  padding: 10px 20px 0 0px;
}
.alingAlert {
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  background: rgba(255, 61, 96, 0.08);
  margin-bottom: 12px;
  margin-top: 12px;
  width: 100%;
}

@include mediaScreen($mediumScreenBreakpoint) {
  .tcalingbox {
    padding-left: 0px;
  }
  .specReadingsOneTwo {
    padding-left: 10px;
  }

  .bathNoPadding {
    padding: 10px 8px 0 0px;
  }
  .specReadingsPadd {
    padding-left: 16px;
  }
  .date_time_style {
    padding-top: 0.5rem;
  }
  .tc_body {
    padding: 12 0 20 16;
  }
  .overviewDetailLayout {
    padding-top: 12px;

    padding-left: 16px;
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
  .overviewLabel {
    font-weight: bold;
  }
  .modalRoot {
    margin-left: unset;
  }
  .header_detail_pos {
    all: unset;
    background: #f5f5f5;
  }
  .modalDiv {
    width: unset;
    min-width: unset;
    max-height: unset;
    min-height: unset;
    padding: unset;

    * {
      font-size: 13px;
    }
  }

  .form {
    grid-template-columns: 1fr;
  }

  .modalTable {
    border-collapse: collapse;
    font-size: 12px;

    .element {
      font-weight: bold;
    }

    .modalTableHead {
      z-index: inherit;
      border: none;

      tr,
      th:not(:first-child) {
        padding: 6px;
      }

      th:first-child {
        padding: 6px;
      }

      .tablehead {
        background-color: unset;
      }

      .tableheadcell {
        background-color: unset;
        color: $quinaryColor;
      }
    }

    .modalTableBody {
      tr,
      td {
        padding: 6px;
      }

      th {
        padding: 6px;
      }
    }

    .modalTableFooter {
      tr,
      th,
      td {
        padding: 6px;
      }
    }
  }

  .suggestions {
    font-size: 15px;
    width: 100%;
    padding: 15px 0px 15px 16px;
  }

  .addDilSuggestionFurnacesize {
    display: inline-block;
    font-size: 10px;
  }

  .headerFlex {
    padding-left: 16px;
    display: inherit;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #606060;
    // padding-bottom: 16px;
    white-space: nowrap;
  }
  .aling_date_time_view_reading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .addDilSuggestion {
    gap: 0.5rem;
    padding-left: 0px;
    display: flex;
  }
  .spectroreading_box {
    padding: 0px;
  }
  .alingMobviewMoreReading {
    display: flex;
    flex-direction: row-reverse;
    padding: 8px 0 20px 0;
  }
  .suggestionHeaderTextSize {
    font-size: 12px;
    .suggestionHeaderTextsizeChild {
      padding-top: 4px;
      font-size: 14px;
    }
  }
  .alingAlert {
    display: flex;
    align-items: center;
    outline: none;
    background: rgba(255, 61, 96, 0.08);
    margin-bottom: 0px;
    margin-top: 0;
    width: 100%;
  }
}
.targetChemistryContainer {
  padding: 12px 0px 10px 0px;
  display: flex;
}
.heatIdStyles {
  padding: 20px 15px 0px;
}
.partNoStyles {
  color: black;
  font-weight: bold;
}
