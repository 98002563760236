@import '../../../main';

$sidebarBackground: #e8f2f8;
$sidebarHeaderBackground: #e8f2f8;
$selectedNavItemBackground: #ffffff;
$textOpacityAnimation: all 0.6s ease 0.5s;

.sidebarContainer {
  height: 100vh;
  padding: 1rem 1.2rem 2.1rem 1.2rem;
  background-color: $sidebarBackground;
  -webkit-box-shadow: inset -1px 16px -1px -1px rgba(33, 126, 192, 0.2);
  box-shadow: inset -1px -1px 16px -1px rgba(33, 126, 192, 0.2);
  z-index: 5;
  position: relative;
  .openButtonStyles {
    all: unset;
    cursor: pointer;
    margin-top: 0.5rem;
  }
  .logoNameWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;
    gap: 1rem;
    i {
      color: #303030;
      font-size: 24px;
    }
    img {
      height: 40px;
    }
  }
}

.closedSidebarContainer {
  padding: 1rem 1rem 2.1rem 1rem;
  .logoNameWrapper {
    img {
      display: none;
    }
  }
  .featuresList {
    .notification {
      display: none;
    }
    .navigationItemText {
      display: none;
    }
  }
}

.featuresList {
  padding-bottom: 1rem;
  height: calc(100vh - 200px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .headingText {
    position: relative;
    padding: 10px 13px;
    hr {
      margin: 0;
      display: none;
    }
    span {
      transition: $textOpacityAnimation;
      opacity: 1;
      font-size: 12px;
      font-weight: 400;
      color: #909090;
    }
  }
  .selectedMarkerContainer {
    display: flex;
    position: relative;
    .navSelectedmarker {
      height: 24px;
      width: 4px;
      background: #1579be;
      border-radius: 0px 4px 4px 0px;
      position: absolute;
      margin-right: 10px;
      top: -3px;
      left: -10px;
    }
  }
  .navigationItemIcon {
    transition: all 0.2s ease;
  }

  .inActiveNavigationIcon {
    filter: grayscale(1);
  }

  .navigationItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 1.2s ease;
    padding: 13px 10px;
    width: fit-content;
    &::before {
      transition: 0.5s ease;
      content: '';
      position: absolute;
      left: -1.2rem;
      height: 100%;
      border-left: 3px solid var(--white-color);
      border-radius: 2px;
      opacity: 0;
      visibility: hidden;
    }
    :hover {
      transition: 0.5s ease;
      &::before {
        opacity: 1;
        visibility: unset;
      }
    }
  }
  .navItemSelected {
    height: 40px;
    width: 100%;
    background-color: $selectedNavItemBackground;
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(33, 126, 192, 0.1);
    border-radius: 4px;

    .navigationItemText {
      color: #1579be;
      font-weight: bold;
    }
  }
  .navigationItemText {
    margin-left: 13px;
    transition: $textOpacityAnimation;
    white-space: nowrap;
    font-size: 12px;
    opacity: 1;
    color: #000;
  }
  .notification {
    margin-left: 0.31rem;
    min-height: 1rem;
    min-width: 1rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(228, 62, 43, 0.2);
    div {
      font-weight: 700;
      color: rgba(228, 62, 43, 1);
      font-size: 0.625rem;
    }
  }
  .cm_header_style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    svg {
      font-size: 90px;
      height: 15px;
    }
  }
}

.overlayContainer {
  margin-left: -20px;
  position: absolute;
  bottom: 46px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  cursor: pointer;
  hr {
    margin: 0;
  }
  .btnContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
    gap: 4px;
    .bottom_btn {
      display: flex;
      position: relative;
      width: 20px;
      align-items: center;
      justify-content: center;
      transition: 300ms ease-in;
      cursor: pointer;
      padding: 8px;
      border-radius: 4px;
      i {
        transition: 300ms ease-in-out;
        color: #aaa;
      }
      span {
        transition: 300ms ease-in-out;
        display: none;
        font-size: 10px;
        white-space: nowrap;
        color: #1579be;
        opacity: 0;
        padding-left: 6px;
      }
      &:hover {
        width: 120px;
        background-color: #e8f2f8;
        span {
          color: #1579be;
          opacity: 1;
          display: block;
        }
        i {
          color: #1579be;
        }
      }
    }
  }
}

.footer_wrapper {
  display: grid;
  place-items: center;
  gap: 10px;
  left: 8px;
  padding: 10px 0;
  border-top: 1px solid #aaaaaa;
  grid-template-columns: 50px 1fr;
  bottom: 0;
  position: absolute;
  transition: $textOpacityAnimation;
  .footerImgContainer {
    border-right: 2px solid #aaa;
    display: flex;
    align-items: center;
    height: 100%;
    width: 60px;
    img {
      max-width: 100%;
      padding-right: 10px;
    }
  }
  .footerData {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #8a8a8a;
    &:nth-of-type(2) {
      font-size: 8px;
    }
    &:hover {
      color: #1579be;
    }
  }
}

@media (max-width: 768px) {
  .closedSidebarContainer {
    padding: 0.5rem 0;
    .logoNameWrapper {
      position: fixed;
      left: 20px;
      z-index: 15;
      top: 12px;
      margin-top: 0;
      display: flex;
      justify-content: flex-end;
    }
  }
}
