
$bgColor: rgb(32, 32, 32);

.tooltip {
  background-color: $bgColor;
  font-size: 0.8rem;
}

.arrow {
  color: $bgColor;
}
