.inputHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  .form {
    background-color: #fff;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #eee;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    max-width: 200px;
    transition: 300ms ease-in;
    outline: none;
    &:hover {
      box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
      border: none;
      outline: none;
    }
  }
  .searchActive {
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
    border: none;
  }
  .filterButton {
    all: unset;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 4px;
    cursor: pointer;
    transition: 300ms ease;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    i {
      font-size: 1.3rem;
      line-height: unset;
    }
    span {
      font-size: 0.8rem;
      line-height: 1rem;
      color: #909090;
      font-weight: 600;
    }
  }
  .filterButton:hover {
    box-shadow: 2px 2px 8px #909090a4;
    background-color: #157abe27;
    border: 1px solid #1579be;
    i {
      color: #1579be;
    }
    span {
      color: #1579be;
    }
  }
  input {
    all: unset;
    font-size: 14px;
    color: #303030;
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
  }
  ::placeholder {
    color: #909090;
    opacity: 0.7;
  }
  button {
    all: unset;
    cursor: pointer;
    transition: 300ms ease-in;
    * {
      font-size: 1.1rem;
    }
  }
  button:hover {
    transform: scale(1.05);
  }
  .buttonStyles {
    background-color: #a1c9e5;
    color: #fff;
    box-shadow: 2px 4px 16px rgba(56, 119, 185, 0.24);
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 10px;
    border: none;
    text-align: center;
    gap: 0.5rem;
    cursor: pointer;
    height: 40px;
    width: 153px;
    span {
      white-space: nowrap;
    }
    svg {
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}
