@import '../../main';
.content {
  scroll-behavior: smooth;
  overflow-x: hidden;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 4px;
  color: #606060;
  width: 600px;
  height: 300px;

  background: #ffffff;
  border-radius: 12px;
}
::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(221, 221, 221, 1);
  border-radius: 30px;
}
::-webkit-scrollbar-thumb {
  background: #909090;
  border-radius: 10px;
}
.header_style {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #303030;
}
.button {
  background: #217ec0;
  color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 9px 20px;
  gap: 10px;
  border: none;
}
.cancel_style {
  background: #fff;
  box-shadow: 12px 8px 24px rgba(33, 126, 192, 0.16);
  color: #217ec0;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 9px 20px;
  gap: 10px;
  border: none;
}
.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: auto;
  gap: 10px;
}
.modalDiv {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #606060;
  width: 600px;
  height: 300px;

  background: #ffffff;
  border-radius: 12px;

  @include boxShadow;
}

@include mediaScreen($mediumScreenBreakpoint) {
  .modalDiv {
    min-width: 90%;
  }
}