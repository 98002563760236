$border_color: #eee;

.MuiTabs_scroller {
  border-bottom: 1px solid $border_color;
  min-height: 30px !important;

  button {
    display: flex;
    flex-direction: row;
    padding: 0 !important;
    border-bottom: 1px solid $border_color;
    min-height: 30px;
    align-items: flex-start;
  }
}

.headerContainer {
  margin-right: 24px;
  font-size: 16px;
  text-transform: none !important;
  i {
    margin-right: 8px;
  }
  &[aria-selected='false'] {
    font-weight: 400;
    color: #909090;
    path {
      fill: #909090;
    }
  }
  &[aria-selected='true'] {
    color: #1579be;
    font-weight: 700;
    path {
      fill: #1579be;
    }
  }
}

.printDesc {
  font-size: 16px;
  font-weight: 700;
  color: #303030;
}

.shareHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  .shareDesc {
    font-size: 12px;
    font-weight: 700;
    color: #303030;
  }
  label {
    span:nth-of-type(1) {
      padding: 0 !important;
    }
    span:nth-of-type(2) {
      font-size: 12px;
      font-weight: 700;
      margin-left: 4px;
    }
  }
}

.modalFooter {
  position: fixed;
  bottom: 24px;
  right: 24px;
  gap: 0.75rem;
  display: flex;

  button {
    backdrop-filter: blur(2px);
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    /* identical to box height */
    border: 1px solid #1579be;
    padding: 8px 24px;
    display: flex;
    align-items: center;
  }

  .cancelButton {
    color: #3877b9;
    background: #e8f2f8;
    i {
      margin-right: 12px;
      font-size: 24px;
    }
    path {
      fill: #1579be;
    }
  }

  .shareButton {
    color: #fff;
    background: #1579be;
    margin-left: 12px;
    i {
      margin-right: 8px;
    }
    path {
      fill: #fff;
    }
  }
}

.contactsData {
  max-height: 130px;
  overflow-y: scroll;
}

.contactsContainer {
  display: flex;
  height: 40px;
  flex-direction: column;
  border: 1px solid $border_color;
  border-radius: 4px;
  padding: 0 8px;
  margin-bottom: 4px;
  margin-right: 2px;
}

.checkedBox {
  label {
    span:nth-of-type(2) {
      font-weight: 700;
      font-size: 13px;
      color: #303030;
    }
  }
}

.unCheckedBox {
  label {
    span:nth-of-type(2) {
      font-weight: 400;
      font-size: 13px;
      color: #909090;
    }
  }
}
