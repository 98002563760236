.flex {
  display: flex !important;
}

.flexcenter {
  align-items: center !important;
  justify-content: center !important;
}

.flexbetween {
  justify-content: space-between !important;
}

.flexaround {
  justify-content: space-around !important;
  align-items: center;
}

.flexend {
  justify-content: flex-end;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

button {
  outline: none;
}


.colflex {
  flex-direction: column;
}

.textcenter {
  text-align: center;
}

.relpos {
  position: relative;
}

.abspos {
  position: absolute;
}

.boldfont {
  font-weight: 700;
}

.margin-bt-15 {
  margin: 15px 0;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.linethrough {
  text-decoration: line-through;
}

h4 {
  font-size: 1.3rem !important;
}

.box-shadow {
  box-shadow: 0px 0px 6px 3px rgb(0 0 0 / 8%);
}

.primary-color {
  color: #297cbe;
}

.back-white {
  background-color: #fff;
}

.customcheckbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.customcheckbox input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 5px;
  left: -22px;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  border-radius: 4px;
  outline: none;
}

.customcheckbox input[type='checkbox']:checked ~ .checkmark {
  background-color: #297cbe;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.customcheckbox input[type='checkbox']:checked ~ .checkmark:after {
  display: block;
}

.customcheckbox .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
a:hover {
  text-decoration: none !important;
}

.randomtext {
  text-align: center;
  padding: 15px 25px;
  font-size: 0.95rem;
  color: rgba(0, 0, 0, 0.65);
}

.spinnerbody {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
}

#cover-spin {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.orientPopUp.modal-dialog {
  max-width: 650px !important;
  text-align: center;
}

.orientPopUp .modal-content {
  padding: 20px;
}

.fontsize8 {
  font-size: 0.8rem;
}

.modalparent .modal {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.fixHeader {
  position: fixed;
  top: 0;
  left: 250px;
  right: 0;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}
.fixHeader .btn {
  font-size: 0.8rem;
  line-height: 1.45;
}
.fixHeader .btn:not(:last-child) {
  margin-right: 15px;
}

.fixHeader {
  position: fixed;
  top: 0;
  left: 250px;
  right: 0;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  padding: 7px 2%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.app_body {
  padding: 75px 15px 15px 15px !important;
}

.table > :not(:first-child) {
  border-top: 0 !important;
}

@media screen and (max-width: 768px) {
  .fixHeader {
    left: 0;
  }
  .fixHeader {
    left: 0;
    justify-content: space-between;
    padding: 15px 5%;
  }
  .app_body {
    padding: 90px 15px 15px 15px;
  }
}
@media screen and (min-width: 768px) {
  .SpecHeader,
  .quoteheader,
  .CheckoutHeader,
  .Submitbtn {
    width: calc(50%);
    margin: 0 auto;
  }

  .ModalSpecHeader {
    width: calc(50% - 140px);
    left: 250px;
    margin: 0 auto;
  }
  .webdiv .container-fluid,
  .topnote {
    width: 50%;
    max-width: 1300px;
    margin: 0 auto;
  }
  .modal {
    width: 100% !important;
    margin: 0 auto;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .SubCatdiv.sticky {
    margin: 0 auto;
    right: 0 !important;
    width: calc(50%);
  }
}

#QtyRangeContainer {
  display: inline-block;
  align-items: center;
}
#QtyRangeContainer:hover {
  cursor: pointer;
}
#ActiveQtyRangeUnit {
  color: #fff;
  background-color: #1579be;
  padding: 1px 6px;
  border-radius: 4px;
  margin: 1px;
  display: inline-block;
}
#NormalQtyRangeUnit {
  padding: 1px 6px;
  border-radius: 4px;
  margin: 1px;
  display: inline-block;
  color: #9a9a9a;
}
#QtyRangeUnitContainer {
  border: 0.1px solid #9a9a9a;
  background-color: #fff;
  border-radius: 5px;
  margin-left: 8px;
  display: inline-block;
  align-items: center;
  font-size: 13px;
}

.bg {
  text-align: center;
  display: flex;
  /* justify-content: space-between; */
  padding: 2px 4px 2px 0px;
  width: 100%;
}

.infoContainer {
}

.info {
  display: inline-block;
  padding: 0px 3px 3px 3px;
  color: #d29800;
  border-radius: 50%;
  border: solid 1.5px #d29800;
  width: 20px;
  height: 20px;
  font-weight: bold;
  text-align: center;
  margin-left: 100%;
}
