@import '../../main';

.uploadButton {
  @include flex;
  padding-top: 30;
  align-items: center;
  gap: 10px;
  span {
    font-size: 14px;
    font-style: italic;
  }
}
.btnContainer {
  display: flex;
  justify-content: flex-end;
}

.calculateBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
  transition: all 0.3s ease-in-out !important;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #fff;
}
.formWrapper {
  background: #fff;
  /* border: 1px solid #d9e4f1; */
  border-radius: 6px;
  padding: 22px 14px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #fff;
}
.inputBox {
  //  @include flex;
  //align-items: center;
  //gap: 15px
  padding-bottom: 20px;
}
.inputbox_date {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgray;
  width: 100%;
  font-size: 14px;
}
.elementSymbol {
  color: #3d3d3d;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  padding-bottom: 2px;
  padding-left: 10px;
  padding-top: 4px;
  letter-spacing: 0.02em;

  color: #3d3d3d;
  display: flex;
  justify-content: space-between;
}
.elementBoxRed {
  background: #fdecea;
  //  border: 1px solid #fda9a9;
  border-radius: 4px;
  width: 100px;
  height: 40px;

  font-size: 12px;
  line-height: 14px;
  //  text-align: center;
  letter-spacing: 0.02em;
  color: #3d3d3d;
}
.elementBoxGreen {
  // @include flexCenter;
  flex-direction: column;
  background: #eaf6ee;
  // border: 1px solid #9edf94;
  border-radius: 4px;
  width: 100px;
  height: 40px;
}

.itemNameInput {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.crossIcon {
  padding: 0;
  margin-right: 6px !important;
  margin: 0;
  margin-left: -3px;

  svg {
    color: #f4b2aa;
  }
}

.crossIcon:hover {
  svg {
    color: #e43e2b;
  }
}

.elementBox {
  @include flexCenter;
  flex-direction: column;

  border: 0.5px solid #ccc;
  border-radius: 5px;
  height: 60px;
  width: $elementBoxWidth;
}
.elementText {
  padding-left: 10px;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #303030;
}
.elementContainer {
  // @include flex;
  // align-items: center;
  // gap: 15px;

  @include flex;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 10px;
  gap: 8px;
}
.formWrapper div:last-child {
  align-items: right;
}
.printBtn {
  color: #157cbe !important;
  background-color: #fff !important;
  border: 1px solid #157cbe !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
  transition: all 0.3s ease-in-out;
}
.printBtnWrapper {
  display: flex;
  justify-content: right;
}
.selectItems {
  .selectItemsTitle {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #606060;
    padding: 16px 0 15px 20px;
    border-bottom: 1px solid #eeeeee;
  }

  width: 100%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
}

.cmNameWrapper {
  font-weight: bold;
  color: #4bb543;
}

.newBox {
  background: #f5f5f5;
  width: 100%;
  // height: 108px;
}
.headerFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 20px;
  line-height: 19px;
  color: #606060;
  padding-bottom: 25px;
  white-space: nowrap;
}
.readingBox {
  display: flex;
  gap: 8px;
  padding-bottom: 8px;
  align-items: center;
  flex-wrap: wrap;
}
.elementBoxTc {
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #3d3d3d;
  width: 100px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
}
.element_name {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  padding-left: 10px;
  color: #3d3d3d;
}
.element_range {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  padding-left: 10px;
  letter-spacing: 0.02em;

  color: #3d3d3d;
}
.TC_heading {
  padding-left: 30px;
  padding-top: 20px;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  color: #606060;
}

.spectro_reading_text {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #606060;
  padding-bottom: 12px;
}
.InputText {
  padding-bottom: 12px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  color: #303030;
}
.reading_input_box {
  width: 100%;
  min-height: 120px;
  display: flex;
  align-items: center;
  gap: 40px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 20px;
}
.raw_material_style {
  display: grid;
  grid-template-columns: auto auto auto;
  padding-left: 20px;

  border-bottom: 1px solid #eeeeee;
}

.add_edit_button {
  width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #6dacd6;
}
.sugg_quantity {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #606060;
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
}

.secondary_Reading_Box {
  width: 100px;
  height: 40px;
  background: #f5f5f5;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  gap: 15px;
  color: #606060;
}

.viewReading {
  text-align: end;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #1579be;
  cursor: pointer;
}
.detail_view {
  padding-bottom: 8px;
  display: grid;
  grid-template-columns: 7fr 1fr;
}

.suggestionBox {
  min-width: 100;

  height: 40px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #303030;
  background: #e8f2f8;
  border: 1px solid #1579be;
  border-radius: 4px;
}

.predicted_spectro_inRange {
  margin-top: 10px;
  width: 100px;
  height: 40px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #303030;
  background: #eaf6ee;
  border-radius: 4px;
}
.predicted_spectro_outRange {
  margin-top: 10px;
  width: 100px;
  height: 40px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #303030;
  background: #fdecea;
  border-radius: 4px;
}

.predicted_spectro_ele {
  padding: 4 0 2 10px;
}

.add_new_prod_style {
  margin-bottom: 10px;
  margin-left: 10px;
  width: 200px;
  height: 40px;
  border: 1px solid #1579be;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #1579be;
}
.addNewItemType {
  display: flex;
  align-items: center;
  width: 173px;
  height: 32px;
  padding-left: 10px;
  margin-left: -7px;
}

.addNewItemType:hover {
  background-color: #1579be;
  cursor: pointer;
}

.view_more_style {
  display: flex;
  align-items: flex-end;
}
.add_edit_product_style {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Primary CTA/Primary CTA - 100% - 1579BE */

  color: #1579be;
  padding-left: 6px;
}
.spectro_reading_bottom_container {
  border-top: 1px solid #eeeeee;
  padding-left: 10;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 20;
}
.not_optimized {
  background: #ffeaee;
  width: 100%;
  border-radius: 4px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff3d60;
  font-weight: bold;
  font-size: 16px;
  border: 1px solid #ffcfd8;
}
.threeGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  grid-gap: 20px;
  padding: 2rem 0;
}
.autoCompleteContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  grid-gap: 1rem;
}

.btnContainerNew {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.noOptionsContainer {
  @include flexCenter;
  flex-direction: column;
}

.noResultText {
  font-size: 14px;
}

.noResultAddNewButton {
  cursor: pointer;
  color: $secondaryColor;
  background-color: $quaternaryColor;
  max-width: 120px;
  padding: 6px 16px;
  text-align: center;
  border-radius: 20px;
  margin-top: 10px;
  font-size: 14px;
}
.input_label {
  font-size: 0.8rem;
  margin-bottom: 0.625rem;
}
