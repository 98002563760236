@import '../../main';

.layout {
  display: flex;
  flex: 1;
  max-height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #fff;

  .layoutBodyContainer {
    display: grid;
    overflow-y: auto;
    overflow-x: auto;
    width: 100%;
    z-index: 2;
    .childrenEle {
      grid-row: 2;
      min-height: calc(100vh - 72px);
      overflow-x: auto;
    }
  }
  [data-header-enabled='true'] {
    grid-template-rows: $headerHeight 1fr;
  }
  [data-header-enabled='false'] {
    grid-template-rows: 1fr;
  }
}
