@import '../../main';

.pagination {
  //  margin-top: 2px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 60px;
  background: #eeeeee;

  border-width: 1px 0px;
  border-style: solid;
  border-color: #eeeeee;
}
.rowsPerPGRapper {
  display: flex;
  align-items: center;
  padding-left: 5%;
}
.PageNoList {
  align-items: center;
  vertical-align: bottom;
  float: right;
  display: flex;
  align-self: center;
  justify-content: flex-end;
  padding-right: 5%;
}
.pagecountbox {
  border-radius: 4px;
  background: #ffffff;
}

@include mediaScreen($mediumScreenBreakpoint) {
  .pagination {
    @include flexCenter;
    margin: 20px 0px;
  }
}
