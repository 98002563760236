.chargemixesNumber {
  font-weight: 600;
  font-size: 1.25rem;
  opacity: 0.6;
}
.chargemixesText {
  font-size: 0.825rem;
  display: flex;
  align-items: center;
  width: max-content;
}
.headerChargemixBoxes {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 12px;
  flex-direction: column;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  max-width: 120px;
  transition: 300ms ease-in;
}

.headerChargemixBoxes:hover {
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.12);
}

.active {
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.12);

  .chargemixesText {
    font-weight: 600;
  }
  .chargemixesNumber {
    opacity: 1;
  }
}
