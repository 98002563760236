.errorBoundaryBtn {
  background-color: #1579be;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 6px 15px;
  font-size: 0.7rem;
  border-radius: 20px;
}
.iconLogo > svg {
  font-size: clamp(8rem, 10vw, 12rem);
  line-height: 12rem;
}
.errorText {
  text-align: center;
  padding: 0px 25px 15px 25px;
  font-size: 0.95rem;
  color: rgba(0, 0, 0, 0.65);
}
