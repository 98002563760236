@import '../../../main';

@mixin textContainerStyles($color) {
  color: $color;
  font-size: 0.75rem;
  padding: 0.25rem;
  border-radius: 0.125rem;
  background: rgba($color, 0.2); // Default Opacity
  border: 1px solid $color;
}

.meltingDashboardHeader {
  padding-left: 15px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: $secondaryTextColor;
  font-size: 13px;
  font-weight: 500;
}

.filterBoxClass {
  flex-direction: column-reverse;
  width: 9rem;
  max-width: unset;
  div:nth-of-type(1) {
    font-size: 1rem;
    font-weight: 400;
  }
  div:last-child {
    font-size: 1.125rem;
  }
}

.dashboardContainer {
  padding: 10px 30px;
}

.dashboardFilterHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.filterBoxesContainer {
  display: flex;
  gap: 0.75rem;
}

.chartContainer {
  padding: 1rem;
  color: $secondaryTextColor;
  font-size: 0.75rem;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #eee;
  width: 100%;
  height: 18.125rem;
  .notFoundText {
    img {
      height: 50px;
      width: 50px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    color: rgb(96, 96, 96, 0.6);
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    transform: translateY(100px);
  }
}

.chartGridDisplay {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 1.5rem;
}

.gridItem {
  display: flex;
  gap: 1.5rem;
}

.chartHeader {
  color: $secondaryTextColor;
  font-size: 0.75rem;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  .textContainer {
    @include textContainerStyles($lightBlue);
  }
  .textFlexContainer {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    .textContainer:nth-child(1) {
      @include textContainerStyles(#d58c00);
    }
    .textContainer:nth-child(2) {
      @include textContainerStyles(#e43e2b);
    }
  }
  .btnContainer {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    button {
      all: unset;
      cursor: pointer;
      color: $secondaryTextColor;
      font-size: 0.75rem;
      font-weight: 400;
      padding: 0.25rem 0.5rem;
      border-radius: 0.125rem;
      background-color: rgb(245, 245, 245);
      transition: all ease-in;
    }
    .activeBtn {
      background: rgb(68, 148, 203);
      color: #fff;
    }
  }
}
