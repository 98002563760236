@import '../../main.scss';

$green: rgba(43, 162, 76, 1);
$fadedGreen: rgba(43, 162, 76, 0.16);

$red: rgba(228, 62, 43, 1);
$fadedRed: rgba(228, 62, 43, 0.1);

$orange: rgba(227, 150, 0, 1);
$fadedOrange: rgba(227, 150, 0, 0.1);

$purple: rgba(150, 54, 225, 1);
$fadedPurple: rgba(150, 54, 225, 0.1);

$primaryTextColour: rgb(48, 48, 48);

.rawMaterialTable {
  td {
    padding: 8px;
    cursor: pointer;
  }
}

.gradeViewTableContainer {
  width: auto;
  margin: 0 -30px !important;
}

.gradeViewTable {
  .displayMinMax {
    @include flexCenter;
    gap: 0.5rem;
    color: $primaryTextColour;
    span:nth-child(1) {
      font-size: 0.75rem;
      font-weight: 700;
    }
    span:nth-child(2) {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
  td {
    padding: 8px;
    cursor: pointer;
  }
}

.warningModalContent {
  font-size: 1rem;
  b {
    color: $primaryTextColour;
  }
}

.headerStyle {
  th {
    color: #909090;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
  }
}

.parent {
  background: #fff;
  margin: -23px;
  padding: 20px 0;
}
.nameTagContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.nameGrade {
  font-size: 10px;
  padding: 2px;
  border-radius: 4px;
  background-color: rgba(96, 96, 96, 0.1);
  text-transform: uppercase;
}

.tagBox {
  font-size: 12px;
  font-weight: 700;
  color: rgba(150, 54, 225, 1);
  background-color: rgba(150, 54, 225, 0.1);
  height: 40px;
  padding: 0 12px;
  width: fit-content;
  border-radius: 4px;
  @include flexCenter;
}
.smallTextDate {
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.grade_category_box {
  color: rgba(43, 162, 76, 1);
  font-weight: 600;
  width: fit-content;
  font-size: 10px;
  background-color: rgba(43, 162, 76, 0.16);
  padding: 1px 8px;
  border-radius: 4px;
}

.confirmationState {
  display: flex;
  align-items: center;
  gap: 1rem;
  .active {
    color: rgb(20, 20, 20);
    background: #eaf6ed;
    border: 1px solid #2ba24c;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.32);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .notActive {
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.32);
    border: 1px solid rgba(228, 62, 43, 0.1);
    border-radius: 4px;
    background-color: #f6eaea;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.gradesTable {
  .gradeTableHeader {
    tr {
      th,
      td {
        font-size: 12px;
        font-weight: 400;
        color: rgba(144, 144, 144, 1);
        padding: 1rem 12px;
        line-height: normal;
      }
    }
  }
  .gradeTableBody {
    tr {
      th,
      td {
        padding: 8px;
        font-size: 12px;
        font-weight: 400;
        color: rgba(48, 48, 48, 1);
      }
    }
    tr:nth-child(odd) {
      background-color: #f5f5f5;
    }
  }
}

.lastUpdated {
  color: rgba(144, 144, 144, 1);
  font-size: 10px;
  font-weight: 400;
  padding: 6px 0px;
}

.smallText {
  font-size: 12px;
  word-break: break-word;
}

.furnaceTag {
  color: $green;
  background: $fadedGreen;
}
.additiveTag {
  color: $red;
  background: $fadedRed;
}
.nodularizerTag {
  color: $purple;
  background: $fadedPurple;
}
.ladleTag {
  color: $orange;
  background: $fadedOrange;
}
.arrowRightButton {
  background: #e8f2f8;
  border: 1px solid $lightBlue;
  backdrop-filter: blur(2px);
  border-radius: 8px;
  padding: 5px;
  z-index: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: -webkit-fill-available;
  justify-content: center;
  color: $lightBlue;
  margin-left: 8px;
  font-weight: 700;
}
.hoverEffectRowActive,
.hoverRowActive:hover {
  transition: width 2s;
  min-height: 72px !important;
}

.hoverEffectRowActive,
.hoverRowActive:hover {
  .createdAt {
    display: none;
  }
  .suggestionButton {
    display: none;
  }
  .functionButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 14px;
    transition: width 2s;
  }
  .hideElement {
    opacity: 0;
    transition: 200ms ease;
  }
  .switch {
    transition: 200ms;
    top: 50%;
    transform: translate(0px, -50%);
    opacity: 1;
  }
  .tick {
    transition: 200ms;
    transform: translateX(-4rem);
    opacity: 0;
  }

  .buttonInner {
    transform: translateX(0%);
    opacity: 1;
  }
  .tagContainerInner {
    transform: translateX(-150px);
    opacity: 0;
  }
}

.compositionWrapper {
  position: relative;
  .greenTick {
    font-size: 1.1rem;
    vertical-align: middle;
    margin-right: 4px;
  }
  .closeIcon {
    font-size: 1.1rem;
    color: #909090;
    margin-right: 4px;
  }
}

.bottomTextRawMaterial {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  padding: 20px;
  color: rgba(0, 0, 0, 0.552);
}

.rmView {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0 0.75rem 0;
}

.rmHeader {
  font-size: 16px;
  font-weight: 700;
  color: #606060;
}
.rmText {
  font-size: 12px;
  color: #606060;
  span {
    text-decoration: underline;
    cursor: pointer;
  }
}

.inputHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  .form {
    background-color: #fff;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #eee;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 200px;
    transition: 300ms ease-in;
    outline: none;
    &:hover {
      box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
      border: none;
      outline: none;
    }
  }
  .searchActive {
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
    border: none;
  }
  .filterButton {
    all: unset;
    padding: 4px 10px;
    border: 1px solid #eee;
    border-radius: 4px;
    cursor: pointer;
    transition: 300ms ease;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    i {
      font-size: 1.3rem;
      line-height: unset;
    }
    span {
      font-size: 0.8rem;
      line-height: 1rem;
      color: #909090;
      font-weight: 600;
    }
  }

  .filterButton:hover {
    box-shadow: 2px 2px 8px #909090a4;
    background-color: #157abe27;
    border: 1px solid $lightBlue;
    i {
      color: $lightBlue;
    }
    span {
      color: $lightBlue;
    }
  }
  input {
    all: unset;
    font: 16px system-ui;
    color: $primaryTextColour;
    height: 100%;
    width: 100%;
    padding: 6px 10px;
    outline: none;
    border: none;
  }
  ::placeholder {
    color: #909090;
    opacity: 0.7;
  }
  .buttonStyles {
    background-color: #a1c9e5;
    color: #fff;
    box-shadow: 2px 4px 16px rgba(56, 119, 185, 0.24);
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 10px;
    border: none;
    text-align: center;
    gap: 0.5rem;
    cursor: pointer;
    height: 40px;
    width: 153px;
    span {
      white-space: nowrap;
    }
    svg {
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}

.rmHeaderWrapper {
  display: flex;
  flex-direction: column;
}
.rm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px 30px 20px 30px;
}

.hideElement {
  opacity: 1;
  transition: 500ms ease;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}
.switch {
  display: flex;
  place-items: center;
  place-content: center;
  transition: 1s ease;
  transform: translate(160px, -50%);
  opacity: 0;
  position: absolute;
  right: -80px;
  top: 50%;
}

.tagContainerInner {
  opacity: 1;
  transform: translateY(-50%) translateX(0%);
  transition: all ease-in 400ms;
}
.buttonInner {
  opacity: 0;
  transform: translateX(-150px) translateY(-50%);
  transition: all ease-in 400ms;
}

.switch div {
  font-size: 0.8rem;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.switch > .active {
  color: rgb(20, 20, 20);
  background: #eaf6ed;
  border: 1px solid #2ba24c;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.32);
  border-radius: 4px;
}
.switch > .notActive {
  background-color: rgba(96, 96, 96, 0.1);
  border-radius: unset;
}

.switch > .notInactive {
  border-radius: 4px;
  border: none;
  background-color: rgba(96, 96, 96, 0.1);
}

.switch > .notInactive:hover {
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.32);
  border: 1px solid rgba(228, 62, 43, 0.1);
  border-radius: 4px;
  background-color: #f6eaea;
}

.switch > .inactive {
  background: #eaf6ed;
  border: 1px solid rgba(228, 62, 43, 1);
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.32);
  border-radius: 4px;
}

.switch > .notActive:hover {
  border: 1px solid #2ba24c1a;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.32);
  border-radius: 4px;
  background-color: #eaf6ed;
}

.switch.active,
.switch.inactive {
  border: 1px solid #2ba24c;
}

.tickContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s ease;
  .tick {
    transition: 1s ease;
    opacity: 1;
  }
}
.tag {
  padding: 1px 0.5rem;
  border-radius: 4px;
  font-size: 0.625rem;
  font-weight: 600;
  width: max-content;
}
.bottomContainer {
  width: 100%;
  position: fixed;
  bottom: 0px;
  right: 8px;
  background-color: #fff;
  z-index: 1;
}
.innerBotttomContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  gap: 15px;
}
.errorText {
  position: absolute;
  bottom: 0;
  transform: translateY(2px);
  font-size: 10px;
  font-weight: 700;
  color: rgb(190, 73, 73);
}
.stepOneButton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 16px;
  background: white;
  gap: 12px;
}

.inputFieldContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-inline: 8px;
  span {
    font-size: 12px;
    font-weight: 700;
    color: rgba(48, 48, 48, 1);
  }
}

.filterIcon {
  color: $breadcrumbColor;
  &:hover {
    color: $lightBlue;
  }
}

.btnContainer {
  display: flex;
  gap: 0.625rem;
  align-items: center;
  justify-content: center;
  color: #1579be;
  font-weight: 700;
  padding: 0 0.75rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  border: 1px solid #1579be;
  background: #e8f2f8;
  backdrop-filter: blur(2px);
  white-space: nowrap;
}

.continueButtton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #1579be;
}

.cancelButton {
  color: #3877b9;
  background: #e8f2f8;
  backdrop-filter: blur(2px);
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  /* identical to box height */
  border: 1px solid #1579be;
  padding: 8px 2rem;
  display: flex;
  align-items: center;
  i {
    margin-right: 12px;
    font-size: 20px;
  }
  path {
    fill: #1579be;
  }
}
