@import '../../main';

.qualityReportsHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px 2rem;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.filterBoxesContainer {
  @include flex;
  gap: 0.625rem;
  @media (max-width: 768px) {
    overflow-x: scroll;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
.spectroHeaderContainer {
  position: fixed;
  z-index: 99;
  width: -webkit-fill-available;
}
.inputHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 768px) {
    width: 100%;
  }
  .form {
    background-color: #fff;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #eee;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 200px;
    transition: 300ms ease-in;
    outline: none;
    &:hover {
      box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
      border: none;
      outline: none;
    }
  }
  .searchActive {
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
    border: none;
  }
  .filterButton {
    all: unset;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 4px;
    cursor: pointer;
    transition: 300ms all ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    i {
      font-size: 1.3rem;
      line-height: unset;
      color: #909090;
    }
    span {
      font-size: 0.8rem;
      line-height: 1rem;
      color: #909090;
      font-weight: 600;
    }
  }
  .filterButton:hover {
    box-shadow: 2px 2px 8px #909090a4;
    background-color: #157abe27;
    border-color: #1579be;
    i {
      color: #1579be;
    }
    span {
      color: #1579be;
    }
  }
  .filterButtonActive {
    background-color: #157abe27;
    border-color: #1579be;
    i {
      color: #1579be;
    }
    span {
      color: #1579be;
    }
  }
  ::placeholder {
    color: #909090;
    opacity: 0.7;
  }
  button {
    cursor: pointer;
    width: auto;
    height: 40px;
    transition: 300ms ease-in;
    * {
      font-size: 1.1rem;
    }
  }
  .gradesNotchParent {
    .buttonNotchStyles {
      right: 6%;
      left: auto;
      transform: none;
    }
  }
  .buttonStyles {
    all: unset;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #1579be;
    border-radius: 4px;
    font-weight: 700;
    font-size: 0.875rem;
    padding: 10px;
    backface-visibility: hidden;
    i {
      font-size: 1.25rem;
      font-weight: 400;
    }
    span {
      white-space: nowrap;
    }
    svg {
      vertical-align: middle;
      margin-right: 8px;
    }
    cursor: pointer;
  }
}

.option {
  color: #fff;
  padding: 0.5rem 1.25rem;
  transition: 300ms all ease-in;
  cursor: pointer;
  @include flex;
  gap: 0.5rem;
  i {
    font-size: 1rem;
  }
  span {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1rem;
  }
  &:hover {
    background-color: #1579be;
  }
}

.filterHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
}
