@import '../../main';

$lightBackground: #e8f2f8;

.productionPlanTableHeaderStyle {
  vertical-align: sub;
  th {
    text-align: right;
    color: $breadcrumbColor;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 8px;
    // Align the first three `th` elements to the left
    &:nth-child(-n + 4) {
      text-align: left;
    }
    &:nth-of-type(1) {
      padding-left: 2rem;
    }
    &:last-of-type {
      padding-right: 2rem;
      text-align: right;
    }
  }
}

.sortIndicator {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  i {
    margin: -6px 0;
    &:nth-of-type(2) {
      color: #303030;
    }
  }
}

.reverseOrder {
  i {
    &:nth-of-type(1) {
      color: #303030;
    }
    &:nth-of-type(2) {
      color: inherit;
    }
  }
}

.partContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: center;
  width: 90%;

  .tippyContainer {
    padding: 0.4rem;
  }

  .partText {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.remainingCountBar {
  border-radius: 0.25rem;
  background: rgba(96, 96, 96, 0.1);
  color: $secondaryTextColor;
  text-align: center;
  font-size: 0.75rem;
  padding: 0.2rem 1rem;
}

.customInputStyles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3rem;
  input {
    width: 12rem;
  }
  fieldset {
    top: -1%;
    border-color: #eee;
    legend {
      display: none;
    }
  }
}
.labelContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}
.labelStyles {
  color: $secondaryTextColor;
  font-size: 0.75rem;
  font-weight: 700;
  .asterisks {
    color: red;
  }
}

.errorMsg {
  font-size: 0.75rem;
  color: #e43e2b;
}

.formContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;

  .formSectionOne {
    padding: 1.5rem 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    border-bottom: 1px solid #eee;
  }
  .formSectionTwo {
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }
}

.formContainerPPC {
  height: calc(100% - 142px);
}

.fieldArrayClass {
  display: grid;
  grid-template-columns: 3fr 2fr 160px 150px 40px;
  align-items: flex-start;
  gap: 0.75rem;
  width: 100%;
  &:nth-child(1) {
    .removePartButton {
      margin-top: 1rem;
    }
  }
}

.totalLiqMetalWt {
  padding: 0px 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  font-size: 0.75rem;
  font-weight: 700;
  background-color: #f5f5f5;
  border: 1px solid #eee;
  border-radius: 0.25rem;
  color: $secondaryTextColor;
}

.totalLiqMetalWtValue {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 0.25rem;
  padding: 0px 12px;
  height: 40px;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  color: $secondaryTextColor;
  background-color: #f5f5f5;
  border: 1px solid #eee;
}

.cmFieldArrayClass {
  grid-template-columns: 1fr 188px 188px 40px;
  align-items: baseline;
  &:nth-child(1) {
    .removePartButton {
      margin-top: 1.25rem;
    }
  }
}

.removePartButton {
  all: unset;
  align-self: center;
  color: #e43e2b;
  border: 1px solid;
  border-radius: 50%;
  height: 1.25rem;
  width: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:disabled {
    color: #eee;
  }
}
.addPartBtn {
  display: flex;
  padding: 0.5rem 8rem 0.5rem 0.75rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid $lightBlue;
  background: #e8f2f8;
  backdrop-filter: blur(2px);
  color: $lightBlue;
  font-size: 0.875rem;
  font-weight: 700;
  cursor: pointer;
  &:disabled {
    background-color: #f5f5f5;
    color: #aaa;
    border-color: #aaa;
  }
}

.chargeMixForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1rem 4rem 1rem;
}
.cmContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.5rem;
  border: 1px solid #f0f0f0;
  background: #fff;
}
.cmContainerPPC {
  height: calc(100% - 142px);
}
.cmHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $primaryTextColor;
  font-size: 1rem;
  padding: 1rem 2rem;
  font-weight: 700;
  border-bottom: 1px solid #eee;
  width: 100%;
}
.cmDetails {
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}
.verificationForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 2rem 5rem 2rem;
  .basicPlanDetail {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }
}
.editBtn {
  all: unset;
  cursor: pointer;
  @include flexCenter;
  color: $lightBlue;
  font-size: 14px;
  font-weight: 700;
  gap: 0.5rem;
}

.basicDetails {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 2rem;
  border-bottom: 1px solid #eee;
  flex-wrap: wrap;
}

.dataBox {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-size: 0.75rem;
  .dataLabel {
    color: #909090;
    text-transform: uppercase;
  }
  .dataValue {
    color: $secondaryTextColor;
  }
}

.tableContainer {
  max-height: 450px;
  width: 100%;
  overflow-x: auto;
}

.styledTable {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.75rem;
  text-align: left;

  thead {
    background-color: #fff;
    th {
      color: #909090;
      text-transform: uppercase;
      padding: 1.25rem 2rem;
      font-weight: 400;
      &:not(&:first-child) {
        text-align: right;
      }
    }
  }

  tbody {
    tr {
      border-top: 1px solid #eee;
      &:nth-child(even) {
        background: rgb(255, 255, 255);
      }

      &:nth-child(odd) {
        background: #f5f5f5;
      }
      &:not(&:last-child) {
        border-bottom: 1px solid #eee;
      }

      &.category {
        td {
          color: $secondaryTextColor;
          text-align: left;
          font-weight: 700;
          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
      td {
        color: $secondaryTextColor;
        font-size: 0.75rem;
        font-weight: 500;
        padding: 0.75rem 2rem;
        &:not(&:first-child) {
          text-align: right;
        }
      }
    }
  }
}

.qtyFieldLabel {
  display: flex;
  justify-content: space-between;
}

.planDetailedView {
  display: flex;
  flex-direction: column;
  .planDetailHeader {
    padding: 1.5rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid #eee;
    background-color: #f5f5f5;
    .planInputContainer {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .planInputs {
      display: flex;
      flex-wrap: wrap;
      gap: 1.25rem;
      .inputBox {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        label {
          color: $secondaryTextColor;
          font-size: 0.75rem;
          font-weight: 700;
        }
        input {
          color: $secondaryTextColor;
          font-size: 0.75rem;
          font-weight: 400;
          border-radius: 0.25rem;
          border: 1px solid #eee;
          background: #eee;
          padding: 0.5rem 0.75rem;
        }
      }
    }
  }
}

.planBottomContainer {
  position: sticky;
  bottom: 0;
  .planFooterContent {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.625rem 0.75rem;
    gap: 0.75rem;
    background-color: #fff;
    border-top: 1px solid #eee;
  }
  .buttonContainer {
    display: flex;
    gap: 0.75rem;
  }
}

.duplicateBtn {
  all: unset;
  padding: 0.625rem;
  display: flex;
  white-space: nowrap;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 700;
  background-color: $lightBlue;
  border-radius: 0.25rem;
  color: #fff;
}
.minMaxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 0.25rem;
  fieldset {
    border: none;
  }
  .tempUnit {
    padding: 0.5rem;
  }
}

.productionPlanRowStyle {
  td {
    padding: 1.5rem 0.5rem;
  }
}

.searchMorePlaceholder {
  padding: 0.5rem;
  color: #909090;
  border-top: 1px solid #aaa;
}

.addSuccessModal {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  .modalHeader {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #2ba24c;
    font-size: 1.125rem;
    font-weight: 700;
    i {
      font-size: 2.5rem;
      font-weight: 400;
    }
  }
  .modalDesc {
    color: $secondaryTextColor;
    font-size: 1rem;
    .highlightedText {
      color: $lightBlue;
      font-weight: 700;
    }
  }
  .modalFooter {
    display: flex;
    gap: 0.5rem;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
  }
}

.gradeInfoMsg {
  font-size: 0.75rem;
}

.labelContainer > i {
  color: #e39600;
}
.ceMeter {
  display: flex;
  gap: 3.75rem;
}

.paddingBox {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #eee;
  width: 100%;
  .boxHeader {
    color: $primaryTextColor;
    font-size: 0.75rem;
    font-weight: 700;
  }
}

.elementsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.finalTag {
  border-radius: 0.25rem;
  padding: 0.25rem 0.75rem;
  color: #9636e1;
  background: #9636e11a;
}

.bathTag {
  border-radius: 0.25rem;
  padding: 0.25rem 0.75rem;
  color: #009797;
  background: #0097971a;
}

.status {
  text-align: center;
  padding: 0.5rem;
  border-radius: 0.25rem;
}
.pendingStatus {
  color: #e43e2b;
  background-color: rgba(228, 62, 43, 0.16);
}

.ongoingStatus {
  color: #d58c00;
  background-color: rgba(213, 140, 0, 0.16);
}

.completedStatus {
  color: #2ba24c;
  background-color: rgba(43, 162, 76, 0.16);
}

.noSuggestionText {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #d58c00;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 1rem;
  i {
    font-size: 1.5rem;
    font-weight: 400;
  }
}

.heatHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.5rem;
}

.totalWtContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
}

.weightDisplayContainer {
  padding: 1.5rem;
  width: 100%;
  border-top: 1px solid #eee;
}

.totalWtItem {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  .totalWtItemLabel {
    color: $secondaryTextColor;
    font-size: 0.75rem;
    font-weight: 700;
  }
  .totalWtItemValue {
    color: $primaryTextColor;
    font-size: 0.75rem;
    font-weight: 400;
  }
}

.muiSelect {
  fieldset {
    border-color: transparent !important;
  }
}
