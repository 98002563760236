@import '../../main';
$green: rgba(43, 162, 76, 1);
$fadedGreen: rgba(43, 162, 76, 0.16);

@import '../../_main.scss';
$red: rgba(228, 62, 43, 1);
$fadedRed: rgba(228, 62, 43, 0.1);

$orange: rgba(227, 150, 0, 1);
$fadedOrange: rgba(227, 150, 0, 0.1);

$purple: rgba(150, 54, 225, 1);
$fadedPurple: rgba(150, 54, 225, 0.1);

.parent {
  background: #fff;
  margin: -23px;
  padding: 20px 0;
}
.nameTagContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.nameGrade {
  font-size: 10px;
  padding: 2px;
  border-radius: 4px;
  background-color: rgba(96, 96, 96, 0.1);
  text-transform: uppercase;
}
.flexCenter {
  @include flexCenter;
}

.ellipsisText {
  max-width: 100px; // Adjust this width as needed
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gradeHeaderStyle {
  th {
    padding: 8px;
    font-size: 12px;
    font-weight: 400;
    color: #909090;
    text-transform: uppercase;
    &:nth-of-type(1) {
      padding-left: 2rem;
    }
  }
}

.gradeTableContainer {
  padding-bottom: 3rem;
}

.qualityReportsTable {
  td:nth-of-type(1) {
    padding-left: 30px;
  }
  td {
    padding: 8px;
  }
}
.tagBox {
  font-size: 12px;
  font-weight: 700;
  color: #9636e1;
  background-color: #9636e110;
  height: 40px;
  padding: 0 18px;
  border-radius: 4px;
  @include flexCenter;
}

.spectrometerTable {
  grid-row: 2;
}
.spectroTableRow {
  td {
    padding: 8px;
    &:nth-child(1) {
      padding-left: 30px;
    }
  }
}
#qualityReportsTableContainer {
  .unAttendedRowStyle {
    background-color: #fcecea;
  }
}

.headerStyles {
  th:nth-of-type(1) {
    padding-left: 30px;
  }
  th {
    color: #909090;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 8px;
  }
}

.rawMaterialCount {
  @extend .tagBox;
  background-color: #60606010;
  width: 40px;
  color: #606060;
}

.smallTextDate,
.smallText {
  font-size: 12px;
  .cmRedirectIcon {
    padding-left: 0.5rem;
    font-size: 1rem;
    color: #1579be;
  }
}
.smallTextDate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.othersTag {
  color: $green;
  background: $fadedGreen;
}
.diTag {
  color: $red;
  background: $fadedRed;
}
.gciTag {
  color: $purple;
  background: $fadedPurple;
}
.ssTag {
  color: $orange;
  background: $fadedOrange;
}

.tagStyleDefault {
  color: $orange;
  background: $fadedOrange;
}
.warningRow:nth-child(odd) {
  background-color: #f5f5f5;
}

.warningRow:nth-child(odd):hover {
  background-color: #fff;
}
.arrowRightButton {
  background: #e8f2f8;
  border: 1px solid #1579be;
  backdrop-filter: blur(2px);
  border-radius: 8px;
  padding: 5px;
  z-index: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: -webkit-fill-available;
  justify-content: center;
  color: #1579be;
  margin-left: 8px;
  font-weight: 700;
}
.dateTimeContainer {
  display: flex;
  align-items: center;
  justify-content: end;
}
.hoverEffectRow {
  transition: width 2s;
  min-height: 72px !important;
  .viewBtn {
    opacity: 0;
    width: fit-content;
  }
  .latestDate {
    opacity: 1;
  }
}

.hoverEffectRow:hover {
  .viewBtn {
    opacity: 1;
    animation: slideOut 0.5s ease-out forwards;
  }
  .latestDate {
    opacity: 0;
    animation: slideIn 0.5s ease-out forwards;
  }
  .createdAt {
    display: none;
  }
  .suggestionButton {
    display: none;
  }
  .functionButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 14px;
    transition: width 2s;
  }
  .hideElement {
    opacity: 0;
    transition: 200ms ease;
  }
  .switch {
    transition: 200ms;
    opacity: 1;
  }
  .tick {
    transition: 200ms;
    transform: translateX(-4rem);
    opacity: 0;
  }
  .editIcon {
    opacity: 1;
    transition: 200ms;
    transform: translateX(0px);
  }
}
.viewButton {
  height: 40px;
  width: 84px;
  @include flexCenter;
  gap: 8px;
  background-color: #e8f2f8;
  color: #1579be;
  border: 1px solid #1579be;
  i {
    font-weight: 700;
  }
}
.compositionWrapper {
  position: relative;
}

.hideElement {
  opacity: 1;
  transition: 500ms ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes slideIn {
  from {
    opacity: 1;
    transform: translateX(0px);
  }
  to {
    opacity: 0;
    transform: translateX(100px);
  }
}

@keyframes slideOut {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(-100px);
  }
}
.switch {
  display: flex;
  place-items: center;
  place-content: center;
  transition: 0.8s ease;
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 0%;
}

.switch div {
  font-size: 0.8rem;
  border-radius: 4px;
  cursor: pointer;
}

.switch > .active {
  color: rgb(20, 20, 20);
  background: #eaf6ed;
  border: 1px solid #2ba24c;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.32);
  border-radius: 4px;
}
.switch > .notActive {
  background-color: rgba(96, 96, 96, 0.1);
  border-radius: unset;
}

.switch > .notInactive {
  border-radius: 4px;
  border: none;
  background-color: rgba(96, 96, 96, 0.1);
}

.switch > .notInactive:hover {
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.32);
  border: 1px solid rgba(228, 62, 43, 0.1);
  border-radius: 4px;
  background-color: #f6eaea;
}

.switch > .inactive {
  background: #eaf6ed;
  border: 1px solid rgba(228, 62, 43, 1);
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.32);
  border-radius: 4px;
}

.switch > .notActive:hover {
  border: 1px solid #2ba24c1a;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.32);
  border-radius: 4px;
  background-color: #eaf6ed;
}

.switch.active,
.switch.inactive {
  border: 1px solid #2ba24c;
}

.tickContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  transition: 1s ease;
  margin: 0px -16px;
  .tick {
    transition: 1s ease;
    position: absolute;
    opacity: 1;
  }
  .editIcon {
    transition: 1s ease;
    opacity: 0;
    transform: translateX(90px);
    padding: 10px;
    cursor: pointer;
    border-radius: 8px;
  }
  .editIcon:hover {
    background-color: rgba(21, 121, 190, 0.1);
  }
}
.tag {
  padding: 1px 0.5rem;
  border-radius: 4px;
  font-size: 0.625rem;
  font-weight: 600;
  width: max-content;
}
.bottomContainer {
  width: 100%;
  position: sticky;
  bottom: 0px;
  right: 0px;
  padding: 5px 0;
  background-color: #fff;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.32);
  .innerBotttomContainer {
    @include flexCenter;
    justify-content: flex-end;
    padding: 15px;
    gap: 15px;
  }
}

.continueButtton,
.cancelButton {
  @include flexCenter;
  gap: 0.5rem;
  border-radius: 0.25rem;
  backdrop-filter: blur(2px);
  i {
    font-size: 1rem;
  }
}
.continueButtton {
  background-color: #1579be;
}

.cancelButton {
  height: 2.5rem;
  border: 1px solid var(--primary-cta-primary-cta-1001579-be, #1579be);
  color: var(--primary-cta-primary-cta-1001579-be, #1579be);
  background: var(--primary-cta-primary-cta-10-e-8-f-2-f-8, #e8f2f8);
  &:hover {
    box-shadow: 4px 4px 16px #1579becc;
    background: var(--primary-cta-primary-cta-10-e-8-f-2-f-8, #e8f2f8);
  }
}
.stepOneButton {
  @include flexCenter;
  justify-content: flex-end;
  padding: 10px 16px;
  background: white;
  gap: 12px;
}

.partDetailsContainer {
  @include flex;
  flex-direction: column;
}

.spectroReadingTag {
  font-size: 0.625rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  height: 2.8rem;
  width: 3.12rem;
}
.bathSampleType {
  color: #009797;
  background: #0097971a;
}
.finalSampleType {
  color: #9636e1;
  background: #9636e11a;
}
.nullSampleType {
  opacity: 0;
}
.tagReadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tagContainer {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}
.tableContainerSpectro {
  td {
    padding: 9px 12px;
  }
}

.btnContainer {
  display: flex;
  gap: 0.625rem;
  align-items: center;
  justify-content: center;
  color: #1579be;
  font-weight: 700;
  padding: 0 0.75rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  border: 1px solid #1579be;
  background: #e8f2f8;
  backdrop-filter: blur(2px);
}
