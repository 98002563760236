@import '../../main';

.mobile {
  left: 0;
}
.web {
  left: 250;
}
.randomtext {
  text-align: center;
  padding: 20px 25px;
  font-size: 1.1rem;
  color: rgb(20, 20, 20);
}
.spinnerbody {
  @include flexCenter;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  z-index: 1;
  background: linear-gradient(360deg, #ffffffba, #fffffff69);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  -ms-backdrop-filter: blur(2px);
  -o-backdrop-filter: blur(2px);
}

.spinner {
  width: 3rem;
  height: 3rem;
  color: #007aff;
}

@include mediaScreen($mediumScreenBreakpoint) {
  .spinnerbody {
    left: 0;
  }
}
