@use 'sass:meta';
@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@200;300;400;500;600;700;800&display=swap');

$fontFamily: 'Oxanium';
$sidebarWidth: 200px;
$closedSidebarWidth: 65px;
$headerHeight: 72px;
$mobileHeaderHeight: 60px;
$mobileContentPadding: 0;
$contentPadding: 20px;
$headerZIndex: 999;
$paddingSmall: 14px;
$paddingLarge: 22px;
$padding: $paddingLarge $paddingSmall;
$borderRadius: 6px;
$border: 1px solid #e6e6e6;
$thinBorder: 0.2px solid #cccccc;
$boxMargin: 20px 0;
$elementBoxWidth: 85px;
$boxWidth: 100px;
// Colors var
$primaryTextColor: #606060;
$secondaryTextColor: #303030;
$tertiaryTextColor: #f5f5f5;
$breadcrumbColor: #909090;
$primaryColor: #d9e4f1;
$secondaryColor: #fff;
$ternaryColor: #000;
$quaternaryColor: #087bcd;
$quinaryColor: #2479ca;
$senaryColor: #007fe8;
$septenaryColor: #102868;
$headerColor: #f0f7ff;
$successColor: #4bb543;
$grayColor: #eee;
$successLightBackgroundColor: #e4f3e5;
$errorBackgroundColor: #f8d7da;
$errorTextColor: #842029;
$lightTitleColor: #6d6d6d;
$redBackground: #ffe0e6;
$lightBlue: rgba(21, 121, 190, 1);

// Media Queries breakpoints
$largeScreenBreakpoint: 900px;
$mediumScreenBreakpoint: 768px;

// Transitions
$sidebarTransition: width 1s cubic-bezier(0.7, 0.08, 0.27, 1.22);
$sidebarTransitionMobile: left 1s ease;

body,
html * {
  font-family: $fontFamily !important;
}

@mixin grid {
  display: grid;
}

@mixin gridTemplateColumns($cols...) {
  display: grid;
  grid-template-columns: $cols;
}

@mixin gridTemplateColumnRepeat($repeat, $len) {
  @include grid;
  grid-template-columns: repeat($repeat, $len);
}

@mixin gridFullWidth {
  grid-column: 1 / -1;
}

@mixin flex {
  display: flex;
}

@mixin flexCenter {
  @include flex;
  align-items: center;
  justify-content: center;
}

@mixin flexBetween {
  @include flex;
  align-items: center;
  justify-content: space-between;
}

// @font-face {
//   font-family: $fontFamily;
//   src: local($fontFamily),
//     url('./assets/fonts/Oxanium-VariableFont_wght.ttf') format('truetype');
//   font-weight: 400;
//   font-display: swap;
// }

@mixin boxShadow {
  box-shadow: 0px 0px 6px 3px rgba($color: $ternaryColor, $alpha: 0.08);
}
::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(170, 170, 170, 1);
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
}
@mixin boxStyles(
  $withBoxShadow: true,
  $isPadding: true,
  $boxSize: large,
  $isMargin: true
) {
  background: $secondaryColor;
  border: 1px solid $primaryColor;
  border-radius: 6px;

  @if $isMargin {
    margin: $boxMargin;
  }

  @if $isPadding {
    @if $boxSize == small {
      padding: 14px;
    } @else {
      padding: $padding;
    }
  }

  @if $withBoxShadow {
    @include boxShadow;
  }
}

@mixin fontWeightMedium {
  font-weight: 900;
}

@mixin headerStyle($fontSize: 15px) {
  @include fontWeightMedium;
  font-size: $fontSize;
}

@mixin gridFlow($flow: column, $gap: 5px) {
  @include grid;
  grid-auto-flow: $flow;
  gap: $gap;
}

@mixin mediaScreen($maxWidth) {
  @media screen and (max-width: $maxWidth) {
    @content;
  }
}
